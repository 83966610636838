.membershipContainer{
    background-color: rgb(247, 241, 241);
}
.gradient-container {
    background: linear-gradient(to right, #fafbfc, #e2a414);
    height:120px;
    width: 270px;
    border-radius: 10px;
    border-left-color: rgb(253, 126, 126);
    padding-left: 20px;
    padding-top: 20px;
    margin-top: 30px;
    
  }
  .totalContainer{
    font-weight: bold;
    color: black;
    font-size: 18px;
    text-align: center;
  }
  .memberShipInputCotainer{
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-around;

  }

  
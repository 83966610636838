.donut{
    height: 200;
    width: fit-content;
    margin: 0 auto;
}
/* .donut >canvas{
    margin: 0 auto;
} */

.bar{
    height: 400px;
    width: 100%;
}
@media (min-width:768px) {
    .donut{
        height: 300px;
    }
    
}
@media (min-width:1024px) {
    .donut{
        height: 400px;
    }
    
}
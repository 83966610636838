.navbardiv{
    width: 100%;
    height: 100px;
    /* border: 1px solid; */
    display: flex;
    background-color:whitesmoke;
    box-shadow: 1px 1px white;
    align-items: center;
    justify-content: space-between;
    background-color: #191919;
    /* flex-wrap: wrap; */
    /* text-decoration: none; */
}
.signOut{
    display: flex;
    flex-direction: column;
    width: 180px;
    justify-content: center;
    align-items: center;
    border: 1px solid whitesmoke;
    background-color:whitesmoke;
    padding: 10px;
    border-radius: 4px;


}
.manageProfile{
    color: black;
    font-size: 16px;
    font-weight: 500;

}
.manageProfile:hover{
    background-color: #ffa300;
    width: 150px;
    border: 1px solid #ffa300; 
    border-radius: 8px;
    text-align: center;
    padding: 2px;

}
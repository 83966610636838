
  .cursor {
    display: inline-block;
    font-weight: bold;
    color: #ffff;
    animation: blink 1s step-end infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
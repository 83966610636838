/* report.css */

.report-container {

    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 104px;
    margin-left: -32rem
}

.report-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.report-buttons button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.report-buttons button.active {
    background-color: #0056b3;
}

.report-heading {
    margin-left: 38rem;
}


.report-dropdown {
    margin-bottom: 20px;
}

.report-dropdown label {
    margin-right: 10px;
}

.report-dropdown select {
    padding: 8px;
    border-radius: 4px;
}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .date-picker-modal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 300px;
  }
  
  .date-picker-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .date-picker-container label {
    font-weight: bold;
  }
  
  .date-picker-container input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .date-picker-modal button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .date-picker-modal button + button {
    margin-left: 10px;
  }
  
  
  /* .report-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
   */
  .report-results {
    
  }
  
  .report-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  .reportContainer{
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 30px;
    font-weight: 600;
    color: black;

  }
  .selectDate{
    font-size: 17px;
    font-weight: 400;
    color: black;
    margin-top: 20px;
  }
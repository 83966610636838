@tailwind base;
@tailwind components;
@tailwind utilities;

/* echo ref: refs/heads/main >.git/HEAD */

body{
  font-family: "REM", sans-serif;
}
.stardos-stencil-bold {
  font-family: "REM", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.roboto-regular {
  font-family: "REM", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.rc-time-picker-input {
  z-index: 1 !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.custom-btn{
border-radius: 16px;
background: #009699;
  
}
/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

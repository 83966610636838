
  .nav-list {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    margin-top: 150px;
    margin-bottom: 20px;
    /* width: 82vw; */
  }
  
  .nav-item {
    color:black;
    font-weight: bold;
    cursor: pointer;
    padding: 8px;
    background-color: black;
    color: white;
    border-radius: 10px;
  }
  
  
 .inventory-container-main{
    width: 100%;
    /* border: 1px solid black; */
   /* height: 100vh; */
   border-radius: 20px;
   margin-left: 20px;
   /* padding: 20px; */
 }
 .inventory-container-main1{
  width: 50vw;
  /* border: 1px solid black; */
  height: 100vh; 
  margin-left: 20px;
  background-color: #f8f9fa;
}

.inventory-container-inner1{
  display: flex;
  gap: 40px;
  margin-left: 8px;
}

.inventory-container-inner2{
  display: flex;
  gap: 40px;
  margin-top: -44px;
}
.dropdown-row{
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  margin-top: 20px;
}
/* label{
  width: 178px;
  height: 26px;
} */
/* YourComponent.css */

.dropdown-option:hover {
  background-color: gray;
}
.add-product1{
  border: 1px solid black;
  width: 50%;
  height: 100vh;
}
.add-product2{
  border: 1px solid black;
  width: 50%;
  height: auto;
  height: 100vh;
}
.add-product-container{
  display: flex;
}

/* ProductTable.css */

.product-table {
  border-collapse: collapse;
  width: 43vw;
}

.product-table th, .product-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.product-table th {
  background-color: #f2f2f2;
}

.product-table td button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  margin: 4px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.searchbyproductName{
  /* border: 1px solid green; */
  border-radius: 8px ;
  width: 200px;
  outline: none;
}
.searchbyproductName:hover{
  border-radius: 8px ;
  width: 200px;
 
}


.table-container {
  overflow-x: auto;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 16px;
  text-align: left;
  border-spacing: 0;
}

.styled-table th,
.styled-table td {
  border-width: 0; 
  border-bottom-width: 1px; 
  border-color: #D9D9D9;
  font-size: 0.875rem;
  line-height: 1.25rem; 
  color: #333333;
  font-weight: 400; 
  background-color: #ffffff;   
}
.styled-table td:not(:first-child){
  color:#979797
}


/* 
.styled-table th {
  background-color: #f2f2f2;
} */
/* 
.styled-table tr {
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.styled-table tr:last-child {
  border-bottom: none;
} */
/* styles.css */

.staff-heading h1 {
    margin-left: 38rem;
    display: flex;
    border-bottom: 3px solid black;
}

.add-staff-button {
    width: 150px;
    height: 50px;
    display: block;

    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 99px;
    margin-left: -79px;
}

.text-input-container {
    display: flex;
    gap: 50px;
    margin-left: 82px;
    
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.table-container1 {
    width: 42vw;
    border-collapse: collapse;
    margin-top: 20px;
    margin-top: 14vw;
    margin-left: -37vw;
    overflow-y: auto;
}
  
  
  .table-header, .table-cell {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table-header {
    background-color: #f2f2f2;
  }
  
  .table-cell {
    background-color: #fff;
  }
  
  .table-cell:hover {
    background-color: #f5f5f5;
  }
  /* Add these styles to your existing CSS file */
.edit-button,
.delete-button {
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
}

.edit-button {
  background-color: #4caf50;
  color: white;
  border: none;
}

.delete-button {
  background-color: #f44336;
  color: white;
  border: none;
}
.teamMember{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: green;
  font-weight: 700;
  margin-top: 20px;
  margin-right: 25px;

}

  



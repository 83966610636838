.login-container {
    width: 450px;
    /* margin: 100px auto; */
    padding: 20px;
    border: 1px solid #ebe9e9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 50px;
    border-radius: 20px ;
    border-color:whitesmoke;
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    color: #333;
  }
  
  input,select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #0000;
    color: white;
    padding: 10px;

    border-radius: 4px;
    cursor: pointer;
  }
  
  